import { Character, ElementType } from "./type";

export const CHARACTERS_LIST: Character[] = [
  { name: "Albedo", rarity: "5", element: ElementType.Geo },
  {
    name: "Alhaitham",
    rarity: "5",
    element: ElementType.Dendro,
  },
  { name: "Aloy", rarity: "5", element: ElementType.Cryo },
  { name: "Amber", rarity: "4", element: ElementType.Pyro },
  {
    name: "Arataki Itto",
    rarity: "5",
    element: ElementType.Geo,
  },
  {
    name: "Arlecchino",
    rarity: "5",
    element: ElementType.Pyro,
  },
  {
    name: "Baizhu",
    rarity: "5",
    element: ElementType.Dendro,
  },
  {
    name: "Barbara",
    rarity: "4",
    element: ElementType.Hydro,
  },
  {
    name: "Beidou",
    rarity: "4",
    element: ElementType.Electro,
  },
  {
    name: "Bennett",
    rarity: "4",
    element: ElementType.Pyro,
  },
  {
    name: "Candace",
    rarity: "4",
    element: ElementType.Hydro,
  },
  {
    name: "Charlotte",
    rarity: "4",
    element: ElementType.Cryo,
  },
  {
    name: "Chevreuse",
    rarity: "4",
    element: ElementType.Pyro,
  },
  {
    name: "Chiori",
    rarity: "5",
    element: ElementType.Geo,
  },
  {
    name: "Chongyun",
    rarity: "4",
    element: ElementType.Cryo,
  },
  {
    name: "Clorinde",
    rarity: "5",
    element: ElementType.Electro,
  },
  {
    name: "Collei",
    rarity: "4",
    element: ElementType.Dendro,
  },
  { name: "Cyno", rarity: "5", element: ElementType.Electro },
  { name: "Dehya", rarity: "5", element: ElementType.Pyro },
  { name: "Diluc", rarity: "5", element: ElementType.Pyro },
  { name: "Diona", rarity: "4", element: ElementType.Cryo },
  { name: "Dori", rarity: "4", element: ElementType.Electro },
  // { name: "Emilie", rarity: "5", element: ElementType.Dendro },
  { name: "Eula", rarity: "5", element: ElementType.Cryo },
  {
    name: "Faruzan",
    rarity: "4",
    element: ElementType.Anemo,
  },
  {
    name: "Fischl",
    rarity: "4",
    element: ElementType.Electro,
  },
  {
    name: "Freminet",
    rarity: "4",
    element: ElementType.Cryo,
  },
  {
    name: "Furina",
    rarity: "5",
    element: ElementType.Hydro,
  },
  {
    name: "Gaming",
    rarity: "4",
    element: ElementType.Pyro,
  },
  { name: "Ganyu", rarity: "5", element: ElementType.Cryo },
  { name: "Gorou", rarity: "4", element: ElementType.Geo },
  { name: "Hu Tao", rarity: "5", element: ElementType.Pyro },
  { name: "Jean", rarity: "5", element: ElementType.Anemo },
  {
    name: "Kaedehara Kazuha",
    rarity: "5",
    element: ElementType.Anemo,
  },
  { name: "Kaeya", rarity: "4", element: ElementType.Cryo },
  {
    name: "Kamisato Ayaka",
    rarity: "5",
    element: ElementType.Cryo,
  },
  {
    name: "Kamisato Ayato",
    rarity: "5",
    element: ElementType.Hydro,
  },
  {
    name: "Kaveh",
    rarity: "4",
    element: ElementType.Dendro,
  },
  {
    name: "Keqing",
    rarity: "5",
    element: ElementType.Electro,
  },
  {
    name: "Kirara",
    rarity: "4",
    element: ElementType.Dendro,
  },
  { name: "Klee", rarity: "5", element: ElementType.Pyro },
  {
    name: "Kujou Sara",
    rarity: "4",
    element: ElementType.Electro,
  },
  {
    name: "Kuki Shinobu",
    rarity: "4",
    element: ElementType.Electro,
  },
  { name: "Layla", rarity: "4", element: ElementType.Cryo },
  { name: "Lisa", rarity: "4", element: ElementType.Electro },
  {
    name: "Lynette",
    rarity: "4",
    element: ElementType.Anemo,
  },
  { name: "Lyney", rarity: "5", element: ElementType.Pyro },
  { name: "Mika", rarity: "4", element: ElementType.Cryo },
  { name: "Mona", rarity: "5", element: ElementType.Hydro },
  {
    name: "Nahida",
    rarity: "5",
    element: ElementType.Dendro,
  },
  {
    name: "Navia",
    rarity: "5",
    element: ElementType.Geo,
  },
  {
    name: "Neuvillette",
    rarity: "5",
    element: ElementType.Hydro,
  },
  { name: "Nilou", rarity: "5", element: ElementType.Hydro },
  {
    name: "Ningguang",
    rarity: "4",
    element: ElementType.Geo,
  },
  { name: "Noelle", rarity: "4", element: ElementType.Geo },
  { name: "Qiqi", rarity: "5", element: ElementType.Cryo },
  {
    name: "Razor",
    rarity: "4",
    element: ElementType.Electro,
  },
  {
    name: "Rosalia",
    rarity: "4",
    element: ElementType.Cryo,
  },
  {
    name: "Sangonomiya Kokomi",
    rarity: "5",
    element: ElementType.Hydro,
  },
  { name: "Sayu", rarity: "4", element: ElementType.Anemo },
  {
    name: "Scaramouche",
    rarity: "5",
    element: ElementType.Anemo,
  },
  {
    name: "Sethos",
    rarity: "4",
    element: ElementType.Electro,
  },
  {
    name: "Shenhe",
    rarity: "5",
    element: ElementType.Cryo,
  },
  {
    name: "Shikanoin Heizou",
    rarity: "4",
    element: ElementType.Anemo,
  },
  {
    name: "Shogun Raiden",
    rarity: "5",
    element: ElementType.Electro,
  },
  {
    name: "Sigewinne",
    rarity: "5",
    element: ElementType.Hydro,
  },
  {
    name: "Sucrose",
    rarity: "4",
    element: ElementType.Anemo,
  },
  {
    name: "Tartaglia",
    rarity: "5",
    element: ElementType.Hydro,
  },
  {
    name: "Thomas",
    rarity: "4",
    element: ElementType.Pyro,
  },
  {
    name: "Tighnari",
    rarity: "5",
    element: ElementType.Dendro,
  },
  { name: "Venti", rarity: "5", element: ElementType.Anemo },
  {
    name: "Voyageur",
    rarity: "5",
    element: [
      ElementType.Anemo,
      ElementType.Geo,
      ElementType.Electro,
      ElementType.Dendro,
      ElementType.Hydro,
    ],
  },
  {
    name: "Wriothesley",
    rarity: "5",
    element: ElementType.Cryo,
  },
  {
    name: "Xiangling",
    rarity: "4",
    element: ElementType.Pyro,
  },
  {
    name: "Xianyun",
    rarity: "5",
    element: ElementType.Anemo,
  },
  { name: "Xiao", rarity: "5", element: ElementType.Anemo },
  {
    name: "Xingqiu",
    rarity: "4",
    element: ElementType.Hydro,
  },
  {
    name: "Xinyan",
    rarity: "4",
    element: ElementType.Pyro,
  },
  {
    name: "Yae Miko",
    rarity: "5",
    element: ElementType.Electro,
  },
  {
    name: "Yanfei",
    rarity: "4",
    element: ElementType.Pyro,
  },
  {
    name: "Yaoyao",
    rarity: "4",
    element: ElementType.Dendro,
  },
  { name: "Yelan", rarity: "5", element: ElementType.Hydro },
  {
    name: "Yoimiya",
    rarity: "5",
    element: ElementType.Pyro,
  },
  {
    name: "Yun Jin",
    rarity: "4",
    element: ElementType.Geo,
  },
  {
    name: "Zhongli",
    rarity: "5",
    element: ElementType.Geo,
  },
];
